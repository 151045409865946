<div class="pt-3 pb-2 mb-3">
	<div class="shadow-lg p-3 mb-5 bg-white rounded table-responsive">
		<table *ngIf="payload" class="table table-hover">
			<thead>
				<tr>
					<th>ID</th>
					<th>Created At</th>
					<th>Kiosk</th>
					<th>Range</th>
					<th>Offer</th>
					<th>Debit MSISDN</th>
					<th>Beneficiary MSISDN</th>
					<th>Amount</th>
					<th>Success</th>
					<th>TP Request</th>
					<th>TP Response</th>
					<th>Error</th>
				</tr>
			</thead>
			<tbody>
				<tr *ngFor="let data of payload">
					<th scope="row">{{data.id}}</th>
					<td>{{data.createdAt | date:'yyyy-MM-dd HH:mm:ss'}}</td>
					<td>{{util.getKioskDetails(data.kiosk)}}</td>
					<td>{{data.range}}</td>
					<td>{{data.offer}}</td>
					<td>{{data.debitMsisdn}}</td>
					<td>{{data.beneficiaryMsisdn}}</td>
					<td>{{data.amount}}</td>
					<td><fa-icon [icon]="getIcon(data.success)" class="text-primary fs-4" [ngClass]="getIconClass(data.success)"></fa-icon></td>
					<!-- <td>{{data.tpRequest}}</td> -->
					<td>
						<button class="btn btn-outline-secondary" 
							(click)="viewTp(data); $event.stopPropagation()" 
							type="button"><fa-icon [icon]="view" size="lg"></fa-icon>
						</button>
					</td>
					<!-- <td>{{data.tpResponse}}</td> -->
					<td>
						<button class="btn btn-outline-secondary" 
							(click)="viewTp(data); $event.stopPropagation()" 
							type="button"><fa-icon [icon]="view" size="lg"></fa-icon>
						</button>
					</td>
					<td>{{data.error}}</td>
				</tr>
			</tbody>
		</table>
	</div>
</div>
