import { Component, Input, OnInit } from "@angular/core";
import { NgbActiveModal } from "@ng-bootstrap/ng-bootstrap";
import { AppToastService } from "src/app/components/toasts/app-toast.service";

@Component({
	selector: 'app-failed-tx-data-modal',
	templateUrl: './failed-tx-data-modal.component.html',
	styleUrls: ['./failed-tx-data-modal.component.scss']
})
export class FailedTxDataModalComponent implements OnInit {
	@Input() data: string;

	formattedJSON: any;

	constructor(
		public modal: NgbActiveModal,
		private toastService: AppToastService,
	) {}

	ngOnInit(): void {
		var json = JSON.parse(this.data);
		document.getElementById("json").innerHTML = JSON.stringify(json, undefined, 2);
	}

	copy() {
		navigator.clipboard.writeText(this.data);
		this.toastService.showInfo({ content: 'Text copied' });
	}

	close() {
		this.modal.close(false);
	}
}