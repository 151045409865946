import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { AppToastService } from "src/app/components/toasts/app-toast.service";
import { HttpKioskService } from "../../../../http-kiosk.service";
import { CustomerPayloadDto, FailedTxDto, KioskDto } from "../../../../dto";
import { ActivatedRoute, Router } from "@angular/router";
import { catchError } from "rxjs/operators";
import { faArrowLeft, faCalendar, faSearch, faTimes } from '@fortawesome/free-solid-svg-icons';
import { UtilService } from "../../../../util.service";
import { NgxSpinnerService } from "ngx-spinner";
import { NgbDateStruct } from "@ng-bootstrap/ng-bootstrap";
import { UrlUtil } from "src/app/utils/url-util";
import { HttpFailedTxService } from "src/app/modules/kiosk/http-failed-tx.service";

@Component({
	selector: 'app-kiosk-failed-tx',
	templateUrl: './kiosk-failed-tx.component.html',
	styleUrls: ['./kiosk-failed-tx.component.scss']
})
export class KioskFailedTxComponent {
	kiosk: KioskDto;
	@Output() reloadKiosk: EventEmitter<any> = new EventEmitter(); 
	back = faArrowLeft;

	util = UtilService;

	fromModel: NgbDateStruct;
	toModel: NgbDateStruct;

	date=faCalendar;
	search=faSearch;
	clearSearch=faTimes;

	failedTx: FailedTxDto[]

	constructor(
		private httpService: HttpKioskService,
		private httpFailedTxService: HttpFailedTxService,
		private spinner: NgxSpinnerService,
		private toastService: AppToastService,
		private router: Router,) {
	}

	@Input() 
	set kioskData(kiosk: KioskDto) {
		if (!kiosk) {
			return;
		}

		this.kiosk = kiosk;
		this.fetchKioskFailedTx();
	}

	private fetchKioskFailedTx() {
		console.log('Fetching failed kiosk transactions for kiosk ID: ', this.kiosk.id);
		let from = new Date();
		if (this.fromModel !== undefined) {
			from.setFullYear(this.fromModel.year);
			from.setMonth(this.fromModel.month - 1);
			from.setDate(this.fromModel.day);
		} else {
			from.setDate(from.getDate() - 2);
		}

		let to = new Date();
		if (this.toModel !== undefined) {
			to.setFullYear(this.toModel.year);
			to.setMonth(this.toModel.month - 1);
			to.setDate(this.toModel.day);
		}

		UrlUtil.setParams(this.router, {from: from, to: to, kiosk: this.kiosk?.id});

		this.spinner.show();
		this.httpFailedTxService.getAll(from, to, this.kiosk.id)
		.pipe(
			catchError(error => {
				this.spinner.hide();
				console.log('Error fetching failed tx', error);
				this.toastService.showDanger({ content: 'Failed to fetch data: ' + (error.error ? error.error.error : error.message) });
				throw error;
			})
		)
		.subscribe(items => {
			this.failedTx = items;
			this.spinner.hide();
		});
	}

	doSearch() {
		if (this.fromModel !== undefined && this.toModel === undefined) {
			this.toastService.showSecondary({ content: 'Please provide to date' });
			return;
		}
		if (this.toModel !== undefined && this.fromModel === undefined) {
			this.toastService.showSecondary({ content: 'Please provide from date' });
			return;
		}
		
		this.fetchKioskFailedTx();
	}
}