const allowedActions = [
	{
		action: 'kiosk-edit',
		role: ['ROLE_SUPER_ADMIN', 'ROLE_ADMIN']
	},
	{
		action: 'kiosk-verify',
		role: ['ROLE_SUPER_ADMIN', 'ROLE_ADMIN']
	},
	{
		action: 'kiosk-invalidate-auth',
		role: ['ROLE_SUPER_ADMIN', 'ROLE_ADMIN']
	},
	{
		action: 'kiosk-transactions',
		role: ['ROLE_SUPER_ADMIN', 'ROLE_ADMIN']
	},
	{
		action: 'kiosk-airtime',
		role: ['ROLE_SUPER_ADMIN', 'ROLE_ADMIN']
	},
	{
		action: 'kiosk-failed-tx',
		role: ['ROLE_SUPER_ADMIN', 'ROLE_ADMIN']
	}
]

export const environment = {
	production: false,
	contextPath: '',
	allowedActions: allowedActions
};
