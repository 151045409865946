import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { AbstractHttpService } from '../../service/http-abstract-service';
import { FailedTxDto } from './dto';


@Injectable({
	providedIn: 'root'
})
export class HttpFailedTxService extends AbstractHttpService {

	constructor(http: HttpClient) {
		super(http, '/failedtx');
	}

	getAll(from: Date, to: Date, kioskId: number): Observable<FailedTxDto[]> {
		var search = {
			from: from, 
			to: to, 
			kioskId: kioskId
		}
		return super.post(`/get`, search);
	}
	getByKioskId(kioskId: number): Observable<FailedTxDto[]> {
		return super.get(`/${kioskId}/get`);
	}
	getFailedTxDataById(failedTxId: number): Observable<FailedTxDto> {
		return super.get(`/${failedTxId}/getdata`);
	}
}
