<div class="modal-header">
	<h4 class="modal-title" id="modal-title">Failed Transaction Data</h4>
	<button type="button" class="btn-close" aria-describedby="modal-title" (click)="close()">
	</button>
</div>
<div class="modal-body table-responsive small">
	<pre id="json"></pre>
</div>
<div class="modal-footer">
	<button type="button" class="btn btn-outline-secondary" (click)="copy()">Copy</button>
	<button type="button" class="btn btn-outline-secondary" (click)="close()">Close</button>
</div>