import { Routes, RouterModule, Route } from '@angular/router';
import { FileSizePipe } from '../../components/file-size.pipe';
import { CopyClipboardDirective } from '../../components/copy-clipboard.directive';
import { NgxDropzoneModule } from 'ngx-dropzone';
import { NgSelectModule } from '@ng-select/ng-select';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { FormsModule } from '@angular/forms';
import { NgModule, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FeatureGuard } from 'src/app/utils/feature.guard';
import { KioskListComponent } from './pages/kiosk-list/kiosk-list.component';
import { VerifyKioskModalComponent } from './pages/kiosk-list/verify-kiosk-modal/verify-kiosk-modal.component';
import { EditKioskModalComponent } from './pages/kiosk-list/edit-kiosk-modal/edit-kiosk-modal.component';
import { ConfirmModalComponent } from './components/confirm-modal/confirm-modal.component';
import { ViewKioskComponent } from './pages/kiosk-list/view-kiosk/view-kiosk.component';
import { KioskProblemsComponent } from './pages/kiosk-problems/kiosk-problems.component';
import { AdminListComponent } from './pages/admin-list/admin-list.component';
import { EditAdminModalComponent } from './pages/admin-list/edit-admin-modal/edit-admin-modal.component';
import { EditAdminRoleModalComponent } from './pages/admin-list/edit-admin-role-modal/edit-admin-role-modal.component';
import { AdminAddComponent } from './pages/admin-add/admin-add.component';
import { LocationListComponent } from './pages/location-list/location-list.component';
import { LocationAddComponent } from './pages/location-add/location-add.component';
import { MapComponent } from './components/map/map.component';
import { LeafletModule } from '@asymmetrik/ngx-leaflet';
import { GeoapifyGeocoderAutocompleteModule } from '@geoapify/angular-geocoder-autocomplete';
import { LocationEditComponent } from './pages/location-edit/location-edit.component';
import { KioskInfoComponent } from './pages/kiosk-list/view-kiosk/kiosk-info/kiosk-info.component';
import { KioskComponentsComponent } from './pages/kiosk-list/view-kiosk/kiosk-components/kiosk-components.component';
import { KioskTransactionsComponent } from './pages/kiosk-list/view-kiosk/kiosk-transactions/kiosk-transactions.component';
import { KioskMaintenanceComponent } from './pages/kiosk-list/view-kiosk/kiosk-maintenance/kiosk-maintenance.component';
import { TimeagoModule } from 'ngx-timeago';
import { EditTechnicianModalComponent } from './pages/technician-list/edit-technician-modal/edit-technician-modal.component';
import { TechnicianListComponent } from './pages/technician-list/technician-list.component';
import { TechnicianAddComponent } from './pages/technician-add/technician-add.component';
import { UserProfileComponent } from 'src/app/pages/user-profile/user-profile.component';
import { environment } from 'src/environments/environment';
import { faBell, faBriefcaseClock, faCheck, faClock, faFileVideo, faGear, faGears, faIdBadge, faKey, faLayerGroup, faLocation, faLocationPin, faMagnifyingGlassPlus, faMoneyBill, faPeopleArrows, faPeopleGroup, faSearchPlus, faTicket, faTimes, faTools, faVideo, faVideoCamera, faVideoSlash, faWrench } from "@fortawesome/free-solid-svg-icons";
import { Page, PageGroup } from 'src/app/domain';
import { SettingsListComponent } from './pages/settings-list/settings-list.component';
import { AlarmListComponent } from './pages/alarm-list/alarm-list.component';
import { MaintenanceComponent } from './pages/alarm-list/maintenance/maintenance.component';
import { MaintenanceAddLogModalComponent } from './pages/alarm-list/maintenance/maintenance-add-log-modal/maintenance-add-log-modal.component';
import { KioskVerifyComponent } from './pages/kiosk-verify/kiosk-verify.component';
import { KioskComponentInitComponent } from './components/kiosk-component-init/kiosk-component-init.component';
import { VideoManagementComponent } from './pages/video-management/video-management.component';
import { UploadVideoComponent } from './pages/video-management/upload-video/upload-video.component';
import { FileDropzoneDirective } from './components/file-dropzone-directive/file-dropzone-directive.component';
import { VideoPlayerComponent } from './pages/video-management/video-player/video-player.component';
import { LinkVideoComponent } from './pages/video-management/link-video/link-video.component';
import { CustomerListComponent } from './components/customers/customer-list.component';
import { CustomerIdCardModalComponent } from './components/customers/customer-id-modal/customer-id-modal.component';
import { CustomerMomoAccountModalComponent } from './components/customers/customer-momo-account-modal/customer-momo-account-modal.component';
import { CustomerViewComponent } from './components/customers/customer-view/customer-view.component';
import { CustomerSimsModalComponent } from './components/customers/customer-sims-modal/customer-sims-modal.component';
import { CustomerImageComponent } from './components/customers/customer-image/customer-image.component';
import { CustomerPrintComponent } from './components/customers/customer-print/customer-print.component';
import { CustomerTableDataComponent } from './components/customers/customer-table-data.component';
import { KioskWithdrawalComponent } from './pages/kiosk-withdrawal/kiosk-withdrawal.component';
import { CameraComponent } from './components/camera/camera.component';
import { WebcamModule } from 'ngx-webcam';
import { ZXingScannerModule } from '@zxing/ngx-scanner';
import { FailedTxListComponent } from './components/customers/failed-tx/failed-tx-list.component';
import { FailedTxTableDataComponent } from './components/customers/failed-tx/failed-tx-table-data.component';
import { KioskFailedTxComponent } from './pages/kiosk-list/view-kiosk/kiosk-failed-tx/kiosk-failed-tx.component';
import { FailedTxDataModalComponent } from './components/customers/failed-tx/failed-tx-data-modal/failed-tx-data-modal.component';
import { AirtimeListComponent } from './components/airtime/airtime-list.component';
import { AirtimeTableDataComponent } from './components/airtime/airtime-table-data.component';
import { TpRequestResponseModalComponent } from './components/airtime/tp-request-response-modal/tp-request-response-modal.component';

//must specify all routes and 2 additional: profile and **

export const pages: PageGroup[] = [
	{
		title: 'Kiosks',
		pages: [
			{ path: 'list', component: KioskListComponent, feature: 'Kiosk', name: 'All Kiosks', routerLink: 'list', icon: faLayerGroup, role: ['ROLE_SUPER_ADMIN', 'ROLE_ADMIN', 'ROLE_TECHNICIAN'] },
			{ path: 'problems', component: KioskProblemsComponent, feature: 'Kiosk', name: 'Problematic Kiosks', routerLink: 'problems', icon: faTicket, role: ['ROLE_SUPER_ADMIN', 'ROLE_ADMIN', 'ROLE_TECHNICIAN'] },
			{ path: 'verify', component: KioskVerifyComponent, feature: 'Kiosk', name: 'Awaiting Verification', routerLink: 'verify', icon: faKey, role: ['ROLE_SUPER_ADMIN', 'ROLE_ADMIN'] },
			{ path: 'withdrawal', component: KioskWithdrawalComponent, feature: 'Kiosk', name: 'Withdrawal', routerLink: 'verify', icon: faMoneyBill, role: ['ROLE_SUPER_ADMIN', 'ROLE_ADMIN'] },
			{ path: 'view/:kioskId', component: ViewKioskComponent, feature: 'Kiosk', name: 'View Kiosk', routerLink: null, icon: null, role: ['ROLE_SUPER_ADMIN', 'ROLE_ADMIN'] },
		]
	},
	{
		title: 'Alarms',
		pages: [
			{ path: 'alarms', component: AlarmListComponent, feature: 'Kiosk', name: 'Alarms', routerLink: 'list', icon: faBell, role: ['ROLE_SUPER_ADMIN', 'ROLE_ADMIN', 'ROLE_TECHNICIAN'] },
			{ path: 'maintenance/:kioskId', component: MaintenanceComponent, feature: 'Kiosk', name: 'Alarm Maintenance', routerLink: null, icon: null, role: ['ROLE_SUPER_ADMIN', 'ROLE_ADMIN', 'ROLE_TECHNICIAN'] },
		]
	},
	{
		title: 'Administration',
		pages: [
			{ path: 'admins', component: AdminListComponent, feature: 'Kiosk', name: 'All Admins', routerLink: 'admins', icon: faPeopleGroup, role: ['ROLE_SUPER_ADMIN', 'ROLE_ADMIN'] },
			{ path: 'add-admin', component: AdminAddComponent, feature: 'Kiosk', name: 'Add Admin', routerLink: 'add-admin', icon: faPeopleArrows, role: ['ROLE_SUPER_ADMIN', 'ROLE_ADMIN'] },
		]
	},
	{
		title: 'Technicians',
		pages: [
			{ path: 'technicians', component: TechnicianListComponent, feature: 'Kiosk', name: 'All Technicians', routerLink: 'technicians', icon: faTools, role: ['ROLE_SUPER_ADMIN', 'ROLE_ADMIN'] },
			{ path: 'add-technician', component: TechnicianAddComponent, feature: 'Kiosk', name: 'Add Technician', routerLink: 'add-technician', icon: faWrench, role: ['ROLE_SUPER_ADMIN', 'ROLE_ADMIN'] },
		]
	},
	{
		title: 'Locations',
		pages: [
			{ path: 'locations', component: LocationListComponent, feature: 'Kiosk', name: 'All Locations', routerLink: 'locations', icon: faLocation, role: ['ROLE_SUPER_ADMIN', 'ROLE_ADMIN'] },
			{ path: 'add-location', component: LocationAddComponent, feature: 'Kiosk', name: 'Add Location', routerLink: 'add-location', icon: faLocationPin, role: ['ROLE_SUPER_ADMIN', 'ROLE_ADMIN'] },
			{ path: 'edit-location/:locationId', component: LocationEditComponent, feature: 'Kiosk', name: 'Edit Location', routerLink: null, icon: null, role: ['ROLE_SUPER_ADMIN', 'ROLE_ADMIN'] },
		]
	},
	{
		title: 'Transactions',
		pages: [
			{ path: 'customers', component: CustomerListComponent, feature: 'Kiosk', name: 'All Customers', routerLink: 'customers', icon: faLocation, role: ['ROLE_SUPER_ADMIN', 'ROLE_ADMIN'] },
			{ path: 'customer-view/:customerId', component: CustomerViewComponent, feature: 'Kiosk', name: 'Customer', routerLink: null, icon: null, role: ['ROLE_SUPER_ADMIN', 'ROLE_ADMIN'] },
			{ path: 'failedtx', component: FailedTxListComponent, feature: 'Kiosk', name: 'Failed Transactions', routerLink: 'failedtx', icon: faTimes, role: ['ROLE_SUPER_ADMIN', 'ROLE_ADMIN'] },
		]
	},
	{
		title: 'Airtime',
		pages: [
			{ path: 'airtime', component: AirtimeListComponent, feature: 'Kiosk', name: 'All Airtime', routerLink: 'airtime', icon: faLocation, role: ['ROLE_SUPER_ADMIN', 'ROLE_ADMIN'] },
		]
	},
	{
		title: 'Video Management',
		pages: [
			{ path: 'video-management', component: VideoManagementComponent, feature: 'Kiosk', name: 'Video Management', routerLink: 'video-management', icon: faFileVideo, role: ['ROLE_SUPER_ADMIN', 'ROLE_ADMIN'] },
			{ path: 'upload-video', component: UploadVideoComponent, feature: 'Kiosk', name: 'Upload Video', routerLink: 'upload-video', icon: faVideo, role: ['ROLE_SUPER_ADMIN', 'ROLE_ADMIN'] },
			{ path: 'edit-video/:videoId', component: UploadVideoComponent, feature: 'Kiosk', name: 'Edit Video', routerLink: null, icon: null, role: ['ROLE_SUPER_ADMIN', 'ROLE_ADMIN'] },
			{ path: 'link-video/:videoId', component: LinkVideoComponent, feature: 'Kiosk', name: 'Link Video', routerLink: null, icon: null, role: ['ROLE_SUPER_ADMIN', 'ROLE_ADMIN'] },
		]
	},
	{
		title: 'Settings',
		pages: [
			{ path: 'settings', component: SettingsListComponent, feature: 'Kiosk', name: 'Settings', routerLink: 'locations', icon: faGear, role: ['ROLE_SUPER_ADMIN'] }
		]
	},
	{
		title: 'Profile',
		pages: [
			{ path: 'profile', component: UserProfileComponent, feature: 'Kiosk', name: 'Profile', routerLink: null, icon: faLocation, role: ['ROLE_SUPER_ADMIN', 'ROLE_ADMIN', 'ROLE_TECHNICIAN'] }
		]
	}
	
];

function getRoutes(): Route[] {
	var out: Route[] = [];
	pages.forEach(item => {
		item.pages.forEach(i => {
			out.push(
				{
					path: i.path, 
					component: i.component, 
					canActivate: [FeatureGuard], 
					data: { 
						feature: 'Kiosk', 
						page: i 
					}
				}
			);
		});
	});
	return out;
}

// const routes: Routes = [
// 	{ path: 'list', component: KioskListComponent, canActivate: [FeatureGuard], data: { feature: 'Kiosk', pageName: 'All Kiosks' } },
// 	{ path: 'problems', component: KioskProblemsComponent, canActivate: [FeatureGuard], data: { feature: 'Kiosk', pageName: 'Problematic Kiosks' } },
// 	{ path: 'view/:kioskId', component: ViewKioskComponent, canActivate: [FeatureGuard], data: { feature: 'Kiosk', pageName: 'View Kiosk' } },
// 	{ path: 'admins', component: AdminListComponent, canActivate: [FeatureGuard], data: { feature: 'Kiosk', pageName: 'All Admins' } },
// 	//Will need to change this to a component to view admins
// 	{ path: 'view-admin/:adminId', component: AdminListComponent, canActivate: [FeatureGuard], data: { feature: 'Kiosk', pageName: 'All Admins' } },
// 	{ path: 'add-admin', component: AdminAddComponent, canActivate: [FeatureGuard], data: { feature: 'Kiosk', pageName: 'Add Admin' } },
// 	{ path: 'profile', component: UserProfileComponent, canActivate: [FeatureGuard], data: { feature: 'Kiosk', pageName: 'Profile' } },
// 	{ path: 'locations', component: LocationListComponent, canActivate: [FeatureGuard], data: { feature: 'Kiosk', pageName: 'All Locations' } },
// 	{ path: 'add-location', component: LocationAddComponent, canActivate: [FeatureGuard], data: { feature: 'Kiosk', pageName: 'Add Location' } },
// 	{ path: 'edit-location/:locationId', component: LocationEditComponent, canActivate: [FeatureGuard], data: { feature: 'Kiosk', pageName: 'Edit Location' } },
// 	{ path: 'technicians', component: TechnicianListComponent, canActivate: [FeatureGuard], data: { feature: 'Kiosk', pageName: 'All Technicians' } },
// 	{ path: 'add-technician', component: TechnicianAddComponent, canActivate: [FeatureGuard], data: { feature: 'Kiosk', pageName: 'Add Technician' } },
// 	{ path: '**', redirectTo: 'list', pathMatch: 'full' },
// ];


@NgModule({
	imports: [
		CommonModule,
		FormsModule,
		FontAwesomeModule,
		WebcamModule,
		ZXingScannerModule,
		NgbModule,
		NgSelectModule,
		NgxDropzoneModule,
		// RouterModule,
		RouterModule.forChild(getRoutes()),
		LeafletModule,
		GeoapifyGeocoderAutocompleteModule.withConfig('b2dd3cbed6214bff987880118895ac01'),
		TimeagoModule.forRoot()
	],
	declarations: [
		KioskListComponent,
		ViewKioskComponent,
		KioskInfoComponent,
		KioskComponentsComponent,
		KioskTransactionsComponent,
		KioskFailedTxComponent,
		KioskMaintenanceComponent,
		VerifyKioskModalComponent,
		EditKioskModalComponent,
		KioskProblemsComponent,
		KioskVerifyComponent,
		KioskWithdrawalComponent,
		ConfirmModalComponent,
		AdminListComponent,
		EditAdminModalComponent,
		EditAdminRoleModalComponent,
		AdminAddComponent,
		LocationListComponent,
		LocationAddComponent,
		LocationEditComponent,
		MapComponent,
		CameraComponent,
		TechnicianListComponent,
		EditTechnicianModalComponent,
		TechnicianAddComponent,
		SettingsListComponent,
		AlarmListComponent,
		MaintenanceComponent,
		MaintenanceAddLogModalComponent,
		KioskComponentInitComponent,
		VideoManagementComponent,
		UploadVideoComponent,
		VideoPlayerComponent,
		LinkVideoComponent,
		CustomerListComponent,
		AirtimeListComponent,
		CustomerSimsModalComponent,
		FailedTxDataModalComponent,
		CustomerIdCardModalComponent,
		CustomerMomoAccountModalComponent,
		CustomerViewComponent,
		CustomerImageComponent,
		CustomerPrintComponent,
		FailedTxListComponent,
		FailedTxTableDataComponent,
		FileDropzoneDirective,
		CustomerTableDataComponent,
		AirtimeTableDataComponent,
		TpRequestResponseModalComponent
	],
	exports: [RouterModule, FileDropzoneDirective]
})
export class KioskModule{ 
	static getPages() {
		return pages;
	}

	// constructor() {
	// 	const newRoutes = [];
	// 	var routes = environment.paths.map(item => {
	// 		return {
	// 			path: item.path, 
	// 			component: item.component, 
	// 			canActivate: [FeatureGuard], 
	// 			data: { 
	// 				feature: 'Kiosk', 
	// 				pageName: item.pageName 
	// 			}
	// 		};
	// 	});
	// 	console.log(routes);

	// 	RouterModule.forChild(routes);
	// }
}
