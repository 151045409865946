import { Component, Input } from "@angular/core";
import { faDatabase, faIdCard, faMoneyBills, faSearch, faSimCard } from "@fortawesome/free-solid-svg-icons";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { ActivatedRoute } from "@angular/router";
import { FailedTxDto } from "../../../dto";
import { FailedTxDataModalComponent } from "./failed-tx-data-modal/failed-tx-data-modal.component";
import { HttpFailedTxService } from "../../../http-failed-tx.service";
import { NgxSpinnerService } from "ngx-spinner";
import { catchError } from "rxjs/operators";
import { AppToastService } from "src/app/components/toasts/app-toast.service";

@Component({
	selector: 'app-failed-tx-table-data',
	templateUrl: './failed-tx-table-data.component.html',
	styleUrls: ['./failed-tx-table-data.component.scss']
})
export class FailedTxTableDataComponent {

	@Input() payload: FailedTxDto[];

	view = faSearch;

	constructor(
		private modalService: NgbModal,
		private httpService: HttpFailedTxService,
		private spinner: NgxSpinnerService,
		private toastService: AppToastService,) {}

	viewData(failedTx: FailedTxDto) {
		this.spinner.show();
		this.httpService.getFailedTxDataById(failedTx.id)
		.pipe(
			catchError(error => {
				this.spinner.hide();
				console.log('Error fetching failed tx', error);
				this.toastService.showDanger({ content: 'Failed to fetch data: ' + (error.error ? error.error.error : error.message) });
				throw error;
			})
		)
		.subscribe(item => {

			if (!item || item === null) {
				this.toastService.showDanger({ content: 'No submission data found for Failed Tx with ID: ' + failedTx.id });
			} else {
				const modalRef = this.modalService.open(FailedTxDataModalComponent, { windowClass: "modal-failed-tx-data", size: 'lg' });
				modalRef.componentInstance.data = item.data;
			}
			this.spinner.hide();
		});

		
	}

}