<div class="pt-3 pb-2 mb-3">
	<div class="shadow-lg p-3 mb-5 bg-white rounded table-responsive">
		<table *ngIf="payload" class="table table-hover">
			<thead>
				<tr>
					<th>ID</th>
					<th>Created At</th>
					<th>Kiosk</th>
					<th>Tx ID</th>
					<th>Tx Type</th>
					<th>Data</th>
				</tr>
			</thead>
			<tbody>
				<tr *ngFor="let data of payload">
					<th scope="row">{{data.id}}</th>
					<td>{{data.createdAt | date:'yyyy-MM-dd HH:mm:ss'}}</td>
					<td>{{data.kiosk.name}}</td>
					<td>{{data.txId}}</td>
					<td>{{data.txType}}</td>
					<td>
						<button class="btn btn-outline-secondary" 
							(click)="viewData(data); $event.stopPropagation()" 
							type="button">
							View&nbsp;&nbsp;<fa-icon [icon]="view" size="lg"></fa-icon>
						</button>
					</td>
				</tr>
			</tbody>
		</table>
	</div>
</div>
