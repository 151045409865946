import { Component, OnInit } from "@angular/core";
import { NgxSpinnerService } from "ngx-spinner";
import { catchError } from "rxjs/operators";
import { AppToastService } from "src/app/components/toasts/app-toast.service";
import { faCalendar, faSearch, faTimes } from "@fortawesome/free-solid-svg-icons";
import { ActivatedRoute, Router } from "@angular/router";
import { NgbDateStruct, NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { UrlUtil } from "src/app/utils/url-util";
import { AirtimeDto, AirtimeOfferDto, AirtimeRangeDto, KioskDto } from "../../dto";
import { HttpAirtimeService } from "../../http-airtime.service";
import { HttpKioskService } from "../../http-kiosk.service";
import { forkJoin } from "rxjs";
import { UtilService } from "../../util.service";

@Component({
	selector: 'app-airtime-list',
	templateUrl: './airtime-list.component.html',
	styleUrls: ['./airtime-list.component.scss']
})
export class AirtimeListComponent implements OnInit {

	payload: AirtimeDto;
	fromModel: NgbDateStruct;
	toModel: NgbDateStruct;
	msisdnModel: string;
	kioskModel: KioskDto;
	airtimeTypeModel: string;
	airtimeRangeModel: AirtimeRangeDto;
	airtimeOfferModel: AirtimeOfferDto;
	airtimeRangeIdModel: number;
	airtimeOfferIdModel: number;
	airtimeAmountStartModel: string;
	airtimeAmountEndModel: string;

	kiosks: KioskDto[];
	airtimeTypes: string[];
	airtimeRanges: AirtimeRangeDto[];
	airtimeOffers: AirtimeOfferDto[];
	airtimeConfig: AirtimeDto;

	date=faCalendar;
	search=faSearch;
	clearSearch=faTimes;

	util = UtilService;

	constructor(
		private httpService: HttpAirtimeService,
		private httpKioskService: HttpKioskService,
		private spinner: NgxSpinnerService,
		private toastService: AppToastService,
		private router: Router,
		private modalService: NgbModal,
		private activatedRoute: ActivatedRoute) {}

	ngOnInit(): void {
		

		this.spinner.show();
		forkJoin([this.httpKioskService.getAll(), this.httpService.getConfig()])
		.pipe(
			catchError(error => {
				this.spinner.hide();
				console.log('Error fetching config', error);
				this.toastService.showDanger({ content: 'Failed to fetch data: ' + (error.error ? error.error.error : error.message) });
				throw error;
			})
		)
		.subscribe(resp => {

			this.kiosks = resp[0];
			this.airtimeConfig = resp[1];
			this.configureParams();
			this.spinner.hide();

			if (this.airtimeConfig.success) {
				this.airtimeTypes = this.airtimeConfig.airtimeType;
				// this.airtimeRanges = this.airtimeConfig.airtimeRange;
				this.fetchAllAirtime();
				return;
			}
			if (this.airtimeConfig.error) {
				console.log('Error fetching this.airtimeConfig', this.airtimeConfig.error);
				this.toastService.showDanger({ content: this.airtimeConfig.error });
				return;
			}
		});
	}

	private configureParams() {
		this.fromModel = UrlUtil.getParamAsNgbDate(this.activatedRoute, 'from');
		this.toModel = UrlUtil.getParamAsNgbDate(this.activatedRoute, 'to');	
		this.msisdnModel = UrlUtil.getParam(this.activatedRoute, 'msisdn');		
		const kioskId = UrlUtil.getParamAsNumber(this.activatedRoute, 'kiosk');
		if (kioskId !== undefined) {
			this.kioskModel = {
				id: kioskId
			};
		}
		this.airtimeTypeModel = UrlUtil.getParam(this.activatedRoute, 'airtimeType');		
		this.airtimeRangeIdModel = +UrlUtil.getParam(this.activatedRoute, 'airtimeRangeId');	
		if (!isNaN(this.airtimeRangeIdModel)) {
			var range = this.airtimeConfig.airtimeRange.filter(item => item.id === this.airtimeRangeIdModel)[0];
			this.airtimeRangeModel = range;
		}	
		this.airtimeOfferIdModel = +UrlUtil.getParam(this.activatedRoute, 'airtimeOfferId');
		if (!isNaN(this.airtimeOfferIdModel)) {
			this.airtimeOfferModel = this.airtimeRangeModel.offers.filter(item => item.id === this.airtimeOfferIdModel)[0];
		}	
		this.airtimeAmountStartModel = UrlUtil.getParam(this.activatedRoute, 'airtimeAmountStart');		
		this.airtimeAmountEndModel = UrlUtil.getParam(this.activatedRoute, 'airtimeAmountEnd');		
	}

	private fetchAllAirtime() {
		let from = new Date();
		if (this.fromModel !== undefined) {
			from.setFullYear(this.fromModel.year);
			from.setMonth(this.fromModel.month - 1);
			from.setDate(this.fromModel.day);
		} else {
			from.setDate(from.getDate() - 2);
		}

		let to = new Date();
		if (this.toModel !== undefined) {
			to.setFullYear(this.toModel.year);
			to.setMonth(this.toModel.month - 1);
			to.setDate(this.toModel.day);
		}

		UrlUtil.setParams(this.router, 
			{
				from: from, 
				to: to,
				msisdn: this.msisdnModel, 
				kiosk: this.kioskModel?.id, 
				airtimeType: this.airtimeTypeModel, 
				airtimeRangeId: this.airtimeRangeIdModel,
				airtimeOfferId: this.airtimeOfferIdModel,
				airtimeAmountStart: this.airtimeAmountStartModel,
				airtimeAmountEnd: this.airtimeAmountEndModel,
			}
		);

		this.spinner.show();
		this.httpService.getAll(from, to, this.msisdnModel, this.kioskModel?.id, this.airtimeTypeModel, this.airtimeRangeIdModel, this.airtimeOfferIdModel, this.airtimeAmountStartModel, this.airtimeAmountEndModel)
		.pipe(
			catchError(error => {
				this.spinner.hide();
				console.log('Error fetching airtime data', error);
				this.toastService.showDanger({ content: 'Failed to fetch data: ' + (error.error ? error.error.error : error.message) });
				throw error;
			})
		)
		.subscribe(item => {
			this.payload = item;
			this.spinner.hide();
		});
	}


	doSearch() {
		if (this.fromModel !== undefined && this.toModel === undefined) {
			this.toastService.showSecondary({ content: 'Please provide to date' });
			return;
		}
		if (this.toModel !== undefined && this.fromModel === undefined) {
			this.toastService.showSecondary({ content: 'Please provide from date' });
			return;
		}
		if (this.fromModel === undefined && this.toModel === undefined && this.msisdnModel === undefined && this.kioskModel === undefined &&
			this.airtimeTypeModel === undefined && this.airtimeOfferIdModel === undefined && this.airtimeAmountStartModel === undefined && this.airtimeAmountEndModel === undefined
		) {
			this.toastService.showSecondary({ content: 'Please capture at least one search field' });
			return;
		}
		
		this.fetchAllAirtime();
	}

	onTypeChange($event) {
		this.clearType();
		this.airtimeTypeModel = $event;
		this.airtimeRanges = this.airtimeConfig.airtimeRange.filter(item => item.airtimeType === this.airtimeTypeModel);
	}
	onRangeChange($event) {
		this.clearRange();
		this.airtimeRangeModel = $event;
		this.airtimeRangeIdModel = this.airtimeRangeModel.id;
		this.airtimeOffers = this.airtimeRangeModel.offers;
		console.log('Selected range: ' + this.airtimeRangeModel.range + ' - id: ' + this.airtimeRangeIdModel);
	}
	onOfferChange($event) {
		this.clearOffer();
		this.airtimeOfferModel = $event;
		this.airtimeOfferIdModel = this.airtimeOfferModel.id;
		console.log('Selected offer: ' + this.airtimeOfferModel.offer + ' - id: ' + this.airtimeOfferIdModel);
	}
	clearType() {
		this.airtimeTypeModel = null;
		this.airtimeRangeModel = null;
		this.airtimeOfferModel = null;
		this.airtimeRangeIdModel = null;
		this.airtimeOfferIdModel = null;
		this.airtimeRanges = null;
		this.airtimeOffers = null;
	}
	clearRange() {
		this.airtimeRangeModel = null;
		this.airtimeOfferModel = null;
		this.airtimeRangeIdModel = null;
		this.airtimeOfferIdModel = null;
		this.airtimeOffers = null;
	}
	clearOffer() {
		this.airtimeOfferModel = null;
		this.airtimeOfferIdModel = null;
	}
}