<div class="row d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center pt-3 pb-2 mb-3 border-bottom">
	<div class="col">	
		<div class="btn-group" role="group">
			<button class="btn btn-outline-secondary" 
				(click)="addTechnician()" 
				type="button">
				Add <fa-icon [icon]="add" size="lg"></fa-icon>
			</button>
		</div>			
	</div>
</div>
<div class="pt-3 pb-2 mb-3">
	<div class="shadow-lg p-3 mb-5 bg-white rounded table-responsive">
		<table *ngIf="maintenancePermissions" class="table">
			<thead>
				<tr>
					<th>Username</th>
					<th>Name</th>
					<th>Surname</th>					
					<th>Config</th>
					<th>Action</th>
				</tr>
			</thead>
			<tbody *ngIf="userPerms">
				<tr *ngFor="let maintenance of maintenancePermissions">
					<td>{{maintenance.user.username}}</td>
					<td>{{maintenance.user.name}}</td>
					<td>{{maintenance.user.surname}}</td>
					<td>
						<div class="row">
							<div class="col-lg-auto d-flex align-items-stretch" *ngFor="let group of getUserPerm(maintenance.user.id).group">
								<div class="card mb-1">
									<div class="card-header">{{group.category}}</div>
									<div class="card-body pt-0 pb-0">
										<div class="table-responsive" style="overflow: visible;">
											<table class="table table-sm table-hover">
												<thead>
													<tr>
														<th>&nbsp;</th>
														<th>Permission</th>
														<th>Allowed</th>
														<th style="width: 100%">Until</th>
													</tr>
												</thead>
												<tbody>
													<tr *ngFor="let config of group.config">
														<td><fa-icon [icon]="tooltip" size="sm" title="{{config.description}}"></fa-icon></td>
														<td>{{config.permission}}</td>
														<td>
															<fa-icon [icon]="hasPerm" *ngIf="config.allowed" class="text-primary fa-icon-no-float" (click)="toggleAllow(config)"></fa-icon>
															<fa-icon [icon]="noPerm" *ngIf="!config.allowed" class="text-danger fa-icon-no-float" (click)="toggleAllow(config)"></fa-icon>
														</td>
														<!-- <td *ngIf="config.allowedUntil">{{config.allowedUntil | date:'yyyy-MM-dd HH:mm:ss'}}&nbsp;<fa-icon [icon]="switch" class="text-primary" (click)="switchAllowedUntil(config)"></fa-icon></td> -->
														 <td *ngIf="config.allowedUntil">
															<div class="col input-group input-group-sm">
																<input
																	class="form-control form-control-sm"
																	placeholder="yyyy-mm-dd"
																	name="dp"
																	[(ngModel)]="config.allowedUntilNgb"
																	ngbDatepicker
																	[placement]="'bottom-end'"
																	#d="ngbDatepicker"
																/>
																<button class="btn btn-outline-secondary" (click)="d.toggle()" type="button"><fa-icon [icon]="date" class="float-end text-secondary"></fa-icon></button>
																&nbsp;<fa-icon [icon]="switch" class="text-primary" (click)="switchAllowedUntil(config)"></fa-icon>
															</div>
														 </td>
														<td *ngIf="!config.allowedUntil">Always&nbsp;<fa-icon [icon]="switch" class="text-primary" (click)="switchAllowedUntil(config)"></fa-icon></td>
													</tr>
												</tbody>
											</table>
										</div>
									</div>
								</div>
							</div>
						</div>
					</td>
					<td>
						<button class="btn btn-outline-secondary" (click)="save(maintenance)" type="button">Save</button>
					</td>
				</tr>
			</tbody>
		</table>
	</div>
	
</div>

<ng-template #roles let-roles='roles'>
	<table class="table">
		<tr *ngFor="let role of roles"><td>{{role.roleName}}</td></tr>
	</table>
</ng-template>
