import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { AbstractHttpService } from '../../service/http-abstract-service';
import { AirtimeDto } from './dto';


@Injectable({
	providedIn: 'root'
})
export class HttpAirtimeService extends AbstractHttpService {

	constructor(http: HttpClient) {
		super(http, '/airtime');
	}

	getConfig(): Observable<AirtimeDto> {
		return super.get(`/airtimeconfig`);
	}

	getAll(from: Date, to: Date, msisdn: string, kioskId: number, airtimeType: string, airtimeRangeId: number, airtimeOfferId: number, airtimeAmountStart: string, airtimeAmountEnd: string): Observable<AirtimeDto> {
		var search = {
			from: from, 
			to: to, 
			msisdn: msisdn, 
			kioskId: kioskId,
			airtimeType: airtimeType,
			airtimeRangeId: airtimeRangeId,
			airtimeOfferId: airtimeOfferId,
			airtimeAmountStart: airtimeAmountStart,
			airtimeAmountEnd: airtimeAmountEnd
		}
		return super.post(`/get-airtime`, search);
	}
	
}
