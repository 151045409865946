<div class="row mt-2 vertical-on-small">
	<div class="col input-group input-group-sm">
		<span class="input-group-text">MSISDN</span>
		<input type="text" size="10" class="form-control" id="msisdn" aria-describedby="msisdn" name="msisdn" [(ngModel)]="msisdnModel" [maxlength]="10">
		<div class="input-group-append input-group-sm" *ngIf="msisdnModel">
			<button type="button" class="btn bg-outline-secondary" (click)="msisdnModel = undefined">
				<fa-icon [icon]="clearSearch" class="text-secondary"></fa-icon>
			</button>
		</div>
	</div>
	<div class="col input-group input-group-sm">
		<span class="input-group-text">Kiosk</span>
		<select name="kiosk" class="form-control" id="kiosk" [(ngModel)]="kioskModel">
			<option *ngFor="let kiosk of kiosks" [ngValue]="kiosk">{{util.getKioskDetails(kiosk)}}</option>
		</select>
		<div class="input-group-append input-group-sm" *ngIf="kioskModel">
			<button type="button" class="btn bg-outline-secondary" (click)="kioskModel = undefined">
				<fa-icon [icon]="clearSearch" class="text-secondary"></fa-icon>
			</button>
		</div>
	</div>
	<div class="col input-group input-group-sm">
		<span class="input-group-text">From</span>
		<input
			class="form-control"
			placeholder="yyyy-mm-dd"
			name="dp"
			[(ngModel)]="fromModel"
			ngbDatepicker
			#d="ngbDatepicker"
		/>
		<button class="btn btn-outline-secondary" (click)="d.toggle()" type="button"><fa-icon [icon]="date" class="float-end text-secondary"></fa-icon></button>
		<div class="input-group-append input-group-sm" *ngIf="fromModel">
			<button type="button" class="btn bg-outline-secondary" (click)="fromModel = undefined">
				<fa-icon [icon]="clearSearch" class="text-secondary"></fa-icon>
			</button>
		</div>
	</div>
	<div class="col input-group input-group-sm">
		<span class="input-group-text">To</span>
		<input
			class="form-control"
			placeholder="yyyy-mm-dd"
			name="dp"
			[(ngModel)]="toModel"
			ngbDatepicker
			#toDate="ngbDatepicker"
		/>
		<button class="btn btn-outline-secondary" (click)="toDate.toggle()" type="button"><fa-icon [icon]="date" class="float-end text-secondary"></fa-icon></button>
		<div class="input-group-append input-group-sm" *ngIf="toModel">
			<button type="button" class="btn bg-outline-secondary" (click)="toModel = undefined">
				<fa-icon [icon]="clearSearch" class="text-secondary"></fa-icon>
			</button>
		</div>
	</div>
	<div class="col input-group input-group-sm">
		<span class="input-group-text">Type</span>
		<select name="airtimeType" class="form-control" id="airtimeType" [(ngModel)]="airtimeTypeModel" (ngModelChange)="onTypeChange($event)">
			<option *ngFor="let airtimeType of airtimeTypes" [ngValue]="airtimeType">{{airtimeType}}</option>
		</select>
		<div class="input-group-append input-group-sm" *ngIf="airtimeTypeModel">
			<button type="button" class="btn bg-outline-secondary" (click)="clearType()">
				<fa-icon [icon]="clearSearch" class="text-secondary"></fa-icon>
			</button>
		</div>
	</div>
	<div class="col input-group input-group-sm">
		<span class="input-group-text">Range</span>
		<select name="airtimeRange" class="form-control" id="airtimeRange" [(ngModel)]="airtimeRangeModel" (ngModelChange)="onRangeChange($event)">
			<option *ngFor="let airtimeRange of airtimeRanges" [ngValue]="airtimeRange">{{airtimeRange.range}}</option>
		</select>
		<div class="input-group-append input-group-sm" *ngIf="airtimeRangeModel">
			<button type="button" class="btn bg-outline-secondary" (click)="clearRange()">
				<fa-icon [icon]="clearSearch" class="text-secondary"></fa-icon>
			</button>
		</div>
	</div>
	<div class="col input-group input-group-sm">
		<span class="input-group-text">Offer</span>
		<select name="airtimeOffer" class="form-control" id="airtimeOffer" [(ngModel)]="airtimeOfferModel" (ngModelChange)="onOfferChange($event)">
			<option *ngFor="let airtimeOffer of airtimeOffers" [ngValue]="airtimeOffer">{{airtimeOffer.offer}}</option>
		</select>
		<div class="input-group-append input-group-sm" *ngIf="airtimeOfferModel">
			<button type="button" class="btn bg-outline-secondary" (click)="clearOffer()">
				<fa-icon [icon]="clearSearch" class="text-secondary"></fa-icon>
			</button>
		</div>
	</div>
	<div class="col text-end">
		<button type="button" class="btn btn-outline-secondary" (click)="doSearch()">
			<fa-icon [icon]="search"></fa-icon>&nbsp;Search
		</button>
	</div>
</div>
<app-airtime-table-data *ngIf="payload" [payload]="payload.data"></app-airtime-table-data>