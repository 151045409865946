import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { AbstractHttpService } from '../../service/http-abstract-service';
import { UserChangePasswordDto, AdminKioskResponseDto, KioskRoleDto, KioskUserDto, KioskCardDispenserStatusDto, KioskDto, KioskPrinterStatusDto, MaintenancePermissionDto } from './dto';


@Injectable({
	providedIn: 'root'
})
export class HttpTechnicianService extends AbstractHttpService {

	constructor(http: HttpClient) {
		super(http, '/technician');
	}

	getAll(): Observable<KioskUserDto[]> {
		return super.get(`/get`);
	}

	getTechnicianByUsername(username: string): Observable<KioskUserDto> {
		return super.get(`/${username}/get`);
	}

	sendOtp(technicianId: number): Observable<AdminKioskResponseDto> {
		return super.get(`/${technicianId}/send-otp`);
	}

	verifyOtp(technicianId: number, otp: number): Observable<AdminKioskResponseDto> {
		return super.get(`/${technicianId}/${otp}/verify-otp`);
	}

	setPassword(username: string, currentPassword: string, newPassword: string, confirmPassword: string): Observable<AdminKioskResponseDto> {
		let payload: UserChangePasswordDto = {
			newPassword: newPassword,
			currentPassword: currentPassword,
			confirmPassword: confirmPassword
		}
		return super.post(`/${username}/set-password`, payload);
	}

	editTechnician(technician: KioskUserDto): Observable<AdminKioskResponseDto> {
		return super.post(`/${technician.id}/edit`, technician);
	}

	changeTechnicianStatus(technicianId: number, status: 'Unblock' | 'Disable' | 'ReEnable'): Observable<AdminKioskResponseDto> {
		return super.get(`/${technicianId}/${status}/action`);
	}

	addTechnician(technician: KioskUserDto): Observable<AdminKioskResponseDto> {
		return super.post(`/add`, technician);
	}

	doMaintenance(qrData: string): Observable<AdminKioskResponseDto> {
		return super.post(`/maintenance`, qrData);
	}

	getAllMaintenancePermissions(): Observable<MaintenancePermissionDto[]> {
		return super.get(`/get-all-maintenance-permissions`);
	}
	saveMaintenancePermissions(config: MaintenancePermissionDto): Observable<AdminKioskResponseDto> {
		return super.post(`/save-maintenance-permissions`, config);
	}
}
